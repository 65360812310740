import { Controller } from "stimulus"

export default class extends Controller {

  closeModal(event) {
    if(event.target.classList.contains('c-modal--open')) {
      Turbo.visit('/');
    }
  }
}
