import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const header = document.getElementById("header");
    document.addEventListener("scroll", function() {
      if (window.pageYOffset > header.offsetTop + 0) {
        header.classList.add("c-header--sticky");
      } else {
        header.classList.remove("c-header--sticky");
      }
    });

    let hamburger = document.getElementById('hamburger-menu');
    let mobileMenu = document.getElementById('mobileMenu');

    document.querySelectorAll('.c-menu-item').forEach((item)=> {
      item.addEventListener('click', function(){
        hamburger.classList.remove('is-active');
        mobileMenu.classList.remove('c-sidenav--open');
      });
    });
  }

  showMenu() {
    let hamburger = document.getElementById('hamburger-menu');
    hamburger.classList.toggle('is-active');

    let mobileMenu = document.getElementById('mobileMenu');
    mobileMenu.classList.toggle('c-sidenav--open');
  }

  showForm() {
    document.querySelectorAll('.c-modal').forEach((item)=>{
      item.remove();
    });
    document.getElementById('show-form').classList.remove('hidden');
    document.getElementById('lead-form').classList.remove('hidden');

    setTimeout(function(){ 
      document.getElementById('footer-helper').scrollIntoView();
      document.getElementById('footer-helper').scrollIntoView();
    }, 200);
  }
}
