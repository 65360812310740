import { Controller } from "stimulus"

export default class extends Controller {
  showForm() {
    document.querySelectorAll('.c-modal').forEach((item)=>{
      item.innerHTML = '';
    });
    document.getElementById('show-form').classList.toggle('hidden');
    document.getElementById('lead-form').classList.toggle('hidden');
  }
}