import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if(!window.Cookies.get('cookieAcknowledged') || window.Cookies.get('cookieAcknowledged') == undefined){
      setTimeout(function(){ 
        document.getElementById('cookies').style.display = 'flex';
      }, 3000);
    }
  }

  closeCookies() {
    Cookies.set('cookieAcknowledged', true);
    document.getElementById('cookies').style.display = 'none';
  }
}
